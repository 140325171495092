<template>
  <div class="system-page page">
    <div class="page-map">收料作業</div>
    <loading :active.sync="isLoading" loader="dots"></loading>
    <div class="card search-card">
      <div class="card-body">
        <div class="form-row">
          <div class="col-sm-3 col-md-2">
            <div class="form-group">
              <label>關鍵字</label>
              <input
                class="form-control"
                type="type"
                v-model="search.keyword"
                placeholder="請輸入關鍵字"
              />
            </div>
          </div>

          <div class="col-sm-12 col-md-3">
            <button
              type="button"
              class="btn btn-primary btn-search"
              @click.prevent="getReceivingOperationList"
            >
              查詢
            </button>
           
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="text-right mb-2">
      <button class="btn btn-primary"  @click="openModal('New')">
        <eva-icon name="plus-outline" fill="#FFF" width="15" height="15"></eva-icon>
      </button>
    </div> -->
    <div class="table-wrap">

    <el-table
      :data="receivingOperationList"
      style="width: 100%"
      class="detail--table"
    >
      <!-- :default-sort="{ prop: 'SpType', order: 'descending' }"
            @expand-change="expandSelect"
            :expand-row-keys="expands"
            row-key="SpId" -->
      <!-- <el-table-column type="expand">
              <template slot-scope="props">
                <el-form label-position="left" class="demo-table-expand">
                  <el-form-item>
                    <table
                      v-show="props.row.SprintBacklogs.length > 0"
                      class="file--table"
                      style="width: 100%"
                    >
                      <tr>
                        <th>Sprint Backlog名稱</th>
                        <th>階段</th>
                        <th>完成率</th>
                        <th>預計完成日</th>
                        <th>預計總工時</th>
                        <th>回報總工時(有效)</th>
                        <th>回報總工時(無效)</th>
                      </tr>
                      <tr
                        v-for="file in props.row.SprintBacklogs"
                        :key="`${file.SbId}${file.SpId}`"
                      >
                        <td>{{ file.SbName }}</td>
                        <td>{{ file.SbLv }}</td>
                        <td>還沒計算</td>
                        <td>{{ file.SbPlanBdate | moment }}</td>
                        <td>{{ file.SbPlanWork}}</td>
                        <td>{{ file.SbValidWork }}</td>
                        <td>{{ file.SbInValidWork}}</td>                       
                      </tr>
                    </table>
                    <div
                      class="text-center"
                      v-show="props.row.SprintBacklogs.length < 1"
                    >
                      尚無工作項目
                    </div>
                    
                  </el-form-item>
                </el-form>
              </template>
            </el-table-column> -->

      <el-table-column label="PO" prop="IRI04"> </el-table-column>

      <el-table-column label="VENDOR" prop="SSI02"> </el-table-column>

      <el-table-column label="PN" prop="IRI07"> </el-table-column>

      <el-table-column label="QTY" prop="IRI27"> </el-table-column>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="props">
          <button class="btn btn-primary btn-sm" @click="openModal('Edit',props.row)">
            <eva-icon name="edit-outline" fill="#FFF" width="15" height="15"></eva-icon>
          </button>        
        </template>
      </el-table-column>
    </el-table>
    </div>


    
    <!-- detailModal -->
    <div
      class="modal fade"
      data-backdrop="static"
      id="detailModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title" id="exampleModalLabel"> <span v-if="isNew">新增</span>收料細項</div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="field">
                        <label class="field-title">來源類別：</label>
                        <input type="text" v-model="detailModal.Type_01">
            </div>
            <div class="field">
                        <label class="field-title">來源單號：</label>
                        <input type="text" v-model="detailModal.PO">
            </div>
            <div class="field">
                        <label class="field-title">單據型態：</label>
                        <input type="text" v-model="detailModal.Type_02">
            </div>
            <div class="field">
                        <label class="field-title">狀態：</label>
                        <input type="text" v-model="detailModal.Status">
            </div>
            <div class="field">
                        <label class="field-title">供應商</label>
                        <input type="text" v-model="detailModal.VENDOR">
            </div>
            <div class="field">
                        <label class="field-title">料號：</label>
                        <input type="text" v-model="detailModal.PN">
            </div>
            <div class="field">
                        <label class="field-title">品名/規格：</label>
                        <input type="text" v-model="detailModal.Name">
            </div>
            <div class="field">
                        <label class="field-title">數量：<input v-if="showCheck" type="checkbox" /> <span v-if="showCheck">樣品超收</span> </label>
                        <input type="number" v-model="detailModal.QTY">
            </div>
          </div>

          <div class="modal-footer justify-content-center">
             <button type="button" class="btn btn-secondary" @click="showCheck=true"  v-if="!showCheck">
              <eva-icon name="plus-outline" fill="#FFF" width="15" height="15"></eva-icon>
            </button>
            
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              <eva-icon name="close-outline" width="15" height="15" fill="#FFF"></eva-icon>
            </button>
            <button type="button" class="btn btn-primary" data-dismiss="modal">
              <eva-icon name="checkmark-outline" width="15" height="15" fill="#FFF"></eva-icon>
            </button>
            <button type="button" class="btn btn-primary" data-dismiss="modal" v-if="!isNew">              
              <eva-icon name="camera-outline" width="15" height="15" fill="#FFF"></eva-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global $ */

export default {
  data() {
    return {
      isNew: true,
      detailModal: {},
      showCheck:false,
      receivingOperationList: [
        // {
        //   PO: "TP021-2109003-1",
        //   PN: "310-100030-0002",
        //   VENDOR: "智翔",
        //   QTY: "3000",
        //   Type_01: "採購單",
        //   Type_02: "原物料採購",
        //   Status: "待收料",
        //   Name: "ADAPTER(Witout Logo)",
        // },
        // {
        //   PO: "TP021-2109003-1",
        //   PN: "310-100030-0002",
        //   VENDOR: "智翔",
        //   QTY: "3000",
        //   Type_01: "採購單",
        //   Type_02: "原物料採購",
        //   Status: "待收料",
        //   Name: "ADAPTER(Witout Logo)",
        // },
        // {
        //   PO: "TP021-2109003-1",
        //   PN: "310-100030-0002",
        //   VENDOR: "智翔",
        //   QTY: "3000",
        //   Type_01: "採購單",
        //   Type_02: "原物料採購",
        //   Status: "待收料",
        //   Name: "ADAPTER(Witout Logo)",
        // },
        // {
        //   PO: "TP021-2109003-1",
        //   PN: "310-100030-0002",
        //   VENDOR: "智翔",
        //   QTY: "3000",
        //   Type_01: "採購單",
        //   Type_02: "原物料採購",
        //   Status: "待收料",
        //   Name: "ADAPTER(Witout Logo)",
        // },
      ],
      search: {
        keyword: '0'
      }
    };
  },
  components: {},
  methods: {
    openModal(type,detail) {
      const vm = this;
      if (type === "New") {
        vm.isNew = true;
        vm.detailModal = {
          PO: null,
          PN: null,
          VENDOR: null,
          QTY: null,
          Type_01: null,
          Type_02:null,
          Status: null,
          Name: null,
        };
      } else {
        vm.isNew = false;
        vm.detailModal = { ...detail };
        
      }
      
      $("#detailModal").modal("show");
    },
    getReceivingOperationList() {
      const api = `http://192.168.1.53/ATP/api/values`

      let params = {
        keyword: this.search.keyword,
        id: 0,
      }

      this.$http.get(api, {params}).then((response) => {
        console.log(response)
        let res = JSON.parse(response.data)
        console.log(res)
        this.receivingOperationList = res.ReceiptInfos
      })
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },

  created() {
    this.getReceivingOperationList();
  },
};
</script>
